import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from '../user/user.service'
import { LoginPayload, StorageKeys } from '../../models/user.model'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router, private userService: UserService) {}

  isAuthenticated() {
    const token = this.getToken()
    return !!token
  }

  setToken(token: string) {
    if (this.userService.getRemember()) {
      localStorage.setItem(StorageKeys.AccessToken, token)
    } else {
      sessionStorage.setItem(StorageKeys.AccessToken, token)
    }
  }

  getToken(): string {
    let token: any = localStorage.getItem(StorageKeys.AccessToken)
    if (!token) {
      token = sessionStorage.getItem(StorageKeys.AccessToken)
    }
    return token
  }

  login(data: LoginPayload) {
    return this.http.post('/api/bo/login', data).toPromise()
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['auth/login'])
  }
}
